import React from 'react'
import Button from '../../molecules/Button'
import { GatsbyImage } from 'gatsby-plugin-image'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import useStaticImage from '../../../hooks/useStaticImage'

import './styles.scss'
import useScreenWidth from '../../../hooks/useScreenWidth'

const CTA = () => {
  const {isTablet, isDesktop} = useScreenWidth()
  const [getImage] = useStaticImage()

  return (
    <section className="cta">
      <div className="cta__container">
        <div className="cta__title__container">
          <h2 className="cta__title">Vamos juntos?</h2>
          <Button
            text="Fale conosco"
            classButton="primary"
            fontColor="orange-dark-bg-dark"
            link="/contato"
          />
        </div>
        {isTablet || isDesktop && (
          <GatsbyImage image={getImage('vetor.webp')} className="cta__logo" alt="DR_" />
        )}
      </div>
    </section>
  )
}

export default CTA
