import { useEffect, useState } from "react";

const MEDIA_QUERIES = {
  mobile: 320,
  tablet: 768,
  laptop: 1025,
  desktop: 1281,
  infinity: 1440,
};

const useScreenWidth = () => {
  const [width, setWidth] = useState(0);
  const [screenSize, setScreenSize] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  });

  const handleResize = () => {
    const updatedWidth = window.innerWidth;
    setWidth(updatedWidth);

    const currentSize = {
      isMobile: updatedWidth < MEDIA_QUERIES.tablet,
      isTablet:
        updatedWidth >= MEDIA_QUERIES.tablet &&
        updatedWidth < MEDIA_QUERIES.desktop,
      isDesktop: updatedWidth >= MEDIA_QUERIES.desktop,
    };

    setScreenSize(currentSize);
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { ...screenSize, width };
};

export default useScreenWidth;
