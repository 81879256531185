import React, { useEffect } from "react"
import "./styles.scss"
import { Link } from "gatsby"

const Button = ({ text, classButton, fontColor, link, onClick, target, ...rest }) => {
  useEffect(() => {
    return () => {
      if (link && (target !== "_blank") && window) {
        window.scrollTo(0, 0);
      }
    }
  }, [])

  return (
    <div>
      <div className={`button__${classButton}--${fontColor}`}>
        {!link ? (
          <button type="button" onClick={onClick} {...rest}>
            {text}
          </button>
        ) : (
          <Link to={link} target={(target ??= "_blank")}>
            {text}
          </Link>
        )}
      </div>
    </div>
  )
}

export default Button
