import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import useStaticImage from '../../../hooks/useStaticImage'

import './styles.scss'

const Banner = ({ image, objectPosition = 'center', alt }) => {
  const [getImage] = useStaticImage()

  return (
    <section className="c_banner">
      <GatsbyImage
        className="c_banner__container"
        image={getImage(image)}
        alt={alt}
        imgStyle={{
          objectPosition,
        }}
      />
    </section>
  )
}

export default Banner
