import React from 'react'
import Layout from '../../../templates/layout'
import Seo from '../../../helpers/seo'
import CTA from '../../../components/organisms/CTA'
import CasesRelational from '../../../components/organisms/CasesRelational'
import Content from './content'
import Banner from '../../../components/molecules/Banner'
import content from './content.json'

const { relational } = content

const SOMALive = () => {
  return (
    <Layout page="SOMA Live">
      <Banner
        alt="Reprodução site Farm - Grupo SOMA | Cliente Grupo SOMA"
        image="soma-live-banner.webp"
      />
      <Content />
      <CTA />
      <CasesRelational relationalData={relational} itsRelationalCase />
    </Layout>
  )
}

export const Head = () => <Seo title="SOMA Live" />

export default SOMALive
